import { useStaticQuery, graphql } from 'gatsby'

const Data = () => {
  const { allProjects, allServices, allProcesses, buttons } = useStaticQuery(graphql`
    query LandingPageItemsQuery {
      buttons: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "buttons" } } }) {
        edges {
          node {
            frontmatter {
              moreButton
              allProcessesButton
              allProjectsButton
              allServicesButton
              contactButton
            }
            fields {
              slug
            }
          }
        }
      }
      allProjects: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "projects" } } }) {
        edges {
          node {
            id
            frontmatter {
              id
              title
              area
              address
              date
              galleryImages {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
      allServices: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "services" } } }) {
        edges {
          node {
            frontmatter {
              id
              number
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              imageWide {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
      allProcesses: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "processes" } } }
      ) {
        edges {
          node {
            frontmatter {
              id
              number
              title
              description
            }
          }
        }
      }
    }
  `)
  return {
    allProjects,
    allServices,
    allProcesses,
    buttons,
  }
}
export default Data
