import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import LayoutHome from '../components/layout/LayoutHome'
import Process from '../components/shared/Process'
import getProcesses from '../hooks/useLandingPageItemsQuery'
import { filterById } from '../utils'

const AboutUs = ({ location, data, pageContext }) => {
  const [selected, setSelected] = useState(null)
  const { allProcesses } = getProcesses()
  const { image, processesSection, aboutUsPageSection } = data.markdownRemark.frontmatter
  const { items, title } = aboutUsPageSection
  const processes = filterById(allProcesses, processesSection.processes).sortByNumber()
  const toggle = i => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  return (
    <LayoutHome
      location={location}
      locale={pageContext.locale}
      locationPath={pageContext.locationPath}
    >
      {!!items.length && (
        <div className="aboutUsPageContainer">
          <h1 className="pageTitle">{title}</h1>
          {items.map((item, i) => {
            return item.image ? (
              <div className="aboutUsRowImage" key={i}>
                <div className="leftSide side">
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
                <div className="rightSide">
                  <GatsbyImage className="img" image={getImage(item.image)} alt="Client" />
                </div>
              </div>
            ) : (
              <div className="aboutUsRow" key={i}>
                <div className="leftSide side">
                  <h1>{item.title}</h1>
                </div>
                <div className="rightSide side">
                  <p>{item.description}</p>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className="imgBgBox">
        <GatsbyImage className="imgBg" image={getImage(image)} alt="Client" />
      </div>
      {!!processes.length && (
        <div className="aboutUsPageContainer">
          <h1 className="pageTitle secondTitle" id="processes">
            {processesSection.title}
          </h1>
          <div className="processesContainer">
            <div className="boxLeft">
              {processes.map((item, i) => (
                <div key={i}>
                  {i < processes.length / 2 && (
                    <Process process={item} toggle={toggle} i={i} selected={selected} />
                  )}
                </div>
              ))}
            </div>
            <div className="boxRight">
              {processes.map((item, i) => (
                <div key={i}>
                  {i > (processes.length - 1) / 2 && (
                    <Process process={item} toggle={toggle} i={i} selected={selected} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </LayoutHome>
  )
}
export default AboutUs
export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        aboutUsPageSection {
          title
          items {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        processesSection {
          processes
          title
        }
      }
    }
  }
`
